/* global testimonial_data, office_location, google, office_icon, office_name, office_address */
$().ready(function(){
	setupSlideMenu();
	fbLike();
	displayTestimonials();
});

$(window).on("load", function(){
	postLoadFacebook();
});

export function setupSlideMenu(){

	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

export function displayTestimonials() {
	if (typeof(testimonial_data) == "undefined") return;
	if (testimonial_data.length < 2) return;
	console.log("rendering testimonials");
	$(".testimonial-body").append("<div class=\"testimonial-next\"></div>");
	$(".testimonial-body").append("<div class=\"testimonial-prev\"></div>");
	var testimonial_index =0;
	function updateTestimonialDisplay() {
		var current_testimonial = testimonial_data[testimonial_index];
		$(".testimonial-name").text(current_testimonial.title);
		$(".testimonial-title").text(current_testimonial.organisation_name);
		$(".testimonial-content").html(current_testimonial.summary);
		$(".testimonial-image").css({
			"background-image":"url('" + current_testimonial.graphic_url +"')"
		});
	}
	$(".testimonial-prev").click(function(){
		testimonial_index++;
		if (testimonial_index>testimonial_data.length -1)
			testimonial_index = 0;
		updateTestimonialDisplay();
	});
	$(".testimonial-next").click(function(){
		testimonial_index--;
		if (testimonial_index<0)
			testimonial_index = testimonial_data.length-1;
		updateTestimonialDisplay();
	});
}

export function initMap() {
	if (office_location.lat && office_location.lng && document.getElementById("map")) {
		var map = new google.maps.Map(document.getElementById("map"), {
			center:office_location,
			zoom: 15,
			scrollwheel: false,
			mapTypeControl: false,
			styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#85c6bb"},{"visibility":"on"}]}]
		});
		var marker_pos = office_location;
		new google.maps.Marker({
			position: marker_pos,
			map: map,
			icon:office_icon,
			title:office_name+" : "+office_address
		});
	} else {
		$("#location-map").remove();
	}
}
window.initMap = initMap;

export function postLoadFacebook(){
	$.ajax({
		url: "/FbProxy/posts?limit=4&fields=type,link,message,created_time,id,full_picture,name,from",
		success:function(data) {
			if (data.data && data.data.length) {
				var $feed = $("#fb-feed .feed");
				for (var i=0; i< data.data.length;i++){
					var post = data.data[i];
					var postHTML = "<!-- rm wsp--><div class=\"fb-post\" data-type=\""+ post.type +"\">";
					postHTML += "<div class=\"profile\">"+
                                "<a href=\"https://facebook.com/176085499111945\" target=\"_blank\" title=\"view profile\">"+
                                "<img class=\"profile-pic\" src=\"https://graph.facebook.com/176085499111945/picture\" />"+
                                "<h4>"+ data.data[0].from.name +"</h4>";
					if (post.created_time.substring(post.created_time.length-3,1) != ":") {
						post.created_time=post.created_time.substring(0,post.created_time.length-2)+":"+post.created_time.substring(post.created_time.length-2,post.created_time.length);
					}
					var postdate = new Date(Date.parse(post.created_time));
					var months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
					postHTML += "<p class=\"date\">"+postdate.getDay().pad()+" "+months[postdate.getMonth()]+" "+postdate.getFullYear()+"</p></a></div>";
					var postid = post.id.split("_")[1];
					postHTML += "<div class=\"text\">";
					postHTML += (post.message || post.name || post.story);
					if (post.type == "link"){
						postHTML += "<p><a class=\"link\" href=\""+ post.link +"\" target=\"_blank\">"+ post.name +"</a></p>";
					}
					postHTML += "</div>";
					if (post.full_picture ) {
						if (post.type == "video"){
							postHTML += "<div class=\"video\" style=\"background-image:url('"+ post.full_picture +"');\">"+
                                        "<a href=\"http://facebook.com/176085499111945/posts/"+postid+"\" title=\"play\" target=\"_blank\"></a></div>";
						} else {
							if (post.type == "link"){
								postHTML += "<div class=\"link-pic\"><a class=\"link\" href=\""+ post.link +"\" target=\"_blank\"></a>";
							}
							postHTML += "<img class=\"image\" src=\""+ post.full_picture +"\"/>";
							if (post.type == "link"){
								postHTML += "</div>";
							}
						}
					}
					postHTML += "</div>";
					$feed.append(postHTML);
				}
				$feed.find(".loader").remove();
			}
		},
		error: function(){
			$("#fb-feed .feed").html("<p class=\"error\">Error connecting to Facebook Posts. Please try again later</p>");
		}
	});
}
Number.prototype.pad = function(size) {
	var s = String(this);
	while (s.length < (size || 2)) {s = "0" + s;}
	return s;
};

export function fbLike(){
	if (($(".fb-like").length > 0) && ($(window).width() <= 500)){
		$(".fb-like").attr("data-layout", "button_count").attr("data-show-faces", "false");
	}
}
